import {loadPlatforms}                 from "../Repository/Requests";
import Actions                         from "./Store/Actions";
import {getLanguage}                   from "../Helpers/MainHelper";
import {SessionsLabels, setSessionKey} from "../Helpers/SessionStorage";
import {isEmpty}                       from "lodash";

export const loadPlatform = () => {
    const {language} = getLanguage();

    return async dispatch => {
        await loadPlatforms()
            .then(response => {
                const {data} = response;
                const [platform] = data.filter(d => d.language === language.toUpperCase());
                setSessionKey(SessionsLabels.PLATFORM_ID, platform.id);
                dispatch({
                    type:    Actions.LOAD_PLATFORM,
                    payload: platform
                });
            }).catch(error => {
                console.log(error);
                dispatch({
                    type:    Actions.LOAD_PLATFORM,
                    payload: {}
                });
            });
    }
}

export const updatePlatform = async (language) => {
    try {
        const platforms = await loadPlatforms();

        if ('status' in platforms.data) throw new Error(platforms.data.data);

        const [platform] = platforms.data.filter(d => d.language === language.toUpperCase());

        const validPlatform = isEmpty(platform) ? platforms.data[0] : platform;
        setSessionKey(SessionsLabels.PLATFORM_ID, validPlatform.id);
        setSessionKey('path', validPlatform.language.toLowerCase());
    } catch (error) {
        console.log(error);
    }
}