import {lazy}                      from "react";
import {Navigate, useRoutes}       from "react-router-dom";
import {getLanguage}               from "../Helpers/MainHelper";
import {setSessionKey, sessionOut} from "../Helpers/SessionStorage";

const Template = lazy(() => import('../Layouts/Template'));
const Catalogue = lazy(() => import('../Views/Catalogue'));
const Login = lazy(() => import('../Views/Login'));
const Courses = lazy(() => import('../Views/Course/Courses'));
const Webinars = lazy(() => import('../Views/Webinars'));
const Notes = lazy(() => import('../Views/Notes'));
const CoursePageInfo = lazy(() => import('../Views/Course/CoursePageInfo'));
const Sections = lazy(() => import('../Views/Sections'));
const Forums = lazy(() => import('../Views/Forums'));
const GetUserInfo = lazy(() => import('../Components/GetUserInfo'));
const ValidateLogin = lazy(() => import('../Components/ValidateLogin'));
const DoLogin = lazy(() => import('../Components/DoLogin'));
const CheckingProgress = lazy(() => import('../Components/CheckingProgress'));
const NoFound = lazy(() => import('../Components/Globals/NoFound'));

const Routes = ({isAuthenticated}) => {
  const {language} = getLanguage()

  const redirect = <Navigate to={"/" + language + "/courses"}/>;
  const sendToLogin = () => {
    let path = window.location.pathname.slice(0, 3);
    if (!isAuthenticated && (path === "/es" || path === "/en"))
      setSessionKey("path", path.slice(1, 3));
    return <Navigate to="/"/>
  }
  const logout = () => {
    sessionOut();
  }

  return useRoutes([
    {
      path:    "/",
      exact:   true,
      element: isAuthenticated ? redirect : <Login/>
    },
    {path: "*", element: <NoFound/>},
    {path: "/getuserinfo", exact: true, element: <GetUserInfo /> },
    {path: "/logout", exact: true, element: () => logout()},
    {path: "/validateLogin", exact: true, element: <ValidateLogin/>},
    {path: "/login", exact: true, element: <DoLogin/>},
    {path: "/checkingprogress", exact: true, element: <CheckingProgress previousPath={window.location.href}/>},
    {
      path:    "/:lang",
      element: isAuthenticated ? <Template/> : sendToLogin(),
      children:
               [
                 {path: "/:lang/catalogue", exact: true, element: <Catalogue/>},
                 {path: "/:lang/courses", exact: true, element: <Courses/>},
                 {path: "/:lang/webinars", exact: true, element: <Webinars/>},
                 {path: "/:lang/notes", exact: true, element: <Notes/>},
                 {path: "/:lang/courses/:slug", exact: true, element: <CoursePageInfo/>},
                 {path: "/:lang/courses/:courseID/sections/:sectionID", exact: true, element: <Sections/>},
                 {path: "/:lang/courses/:courseID/sections/:sectionID/forum", exact: true, element: <Forums/>},
                 {path: "/:lang/courses/:courseID/forum", exact: true, element: <Forums/>},
               ]
    }
  ]);
}

export default Routes