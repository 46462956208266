import ReactDOM from 'react-dom/client';
import App      from './App';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //Add auht0Provider and setup
<Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    scope="openid profile email read:current_user update:current_user_metadata"
    authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    }}
    >
        <App/>
    </Auth0Provider>
);
