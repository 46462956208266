import AppRouter    from "./Routes/AppRouter"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Store        from "./Services/Store/Store";
import { Provider } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import SplashScreen from "./Components/Globals/SplashScreen";

const App = () => {
    const { isAuthenticated, isLoading} = useAuth0();

    if(isLoading){
        console.log("Is authenticated", isAuthenticated);
        return <SplashScreen/>
    }


    return <Provider store={Store}>
        <AppRouter />
    </Provider>
}

export default App;
