import {BrowserRouter}                    from "react-router-dom";
import {useDispatch, useSelector}         from "react-redux";
import {useEffect}                        from "react";
import Routes                             from "./routes";
import {userValidateSession}              from "../Services/UserServices";
import {loadPlatform}                     from "../Services/PlatformsServices";
import {getHeaderMenu, loadConfiguration} from "../Services/ConfigurationMenuServices";
import {validateSession}                  from "../Helpers/SessionStorage";
import {AddEventListenerToLinkRedirect, RemoveEventListenerToLinkRedirect} from "../Components/Globals/RedirectModal";

const AppRouter = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        /*dispatch(ssoSloVerifySession())
        .then(async () => await dispatch(userValidateSession()) --- Auth0 migration - comment SSO and SLO verify session ---
        */ 
        dispatch(userValidateSession()) // delete this line when uncomment the above lines, dont forget to import userValidateSession
        .then(async () => {
            if (validateSession()) {
                await dispatch(loadPlatform());
                await dispatch(loadConfiguration());
                await dispatch(getHeaderMenu());
            }
        }).then(() => AddEventListenerToLinkRedirect());

        return () => RemoveEventListenerToLinkRedirect();

        
    }, [dispatch]);

    const {payload: uid} = useSelector(state => state.user);
    const {REACT_APP_BASE_PATH} = process.env;

    return <BrowserRouter basename={REACT_APP_BASE_PATH}>
        <Routes isAuthenticated={uid}/>
    </BrowserRouter>
};

export default AppRouter;