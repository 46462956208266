import axios                                       from "axios";
import {getSessionKey, SessionsLabels, sessionOut} from "../Helpers/SessionStorage";

const InstanceAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

InstanceAxios.interceptors.request.use(config => {
    config.headers['Authorization'] = getSessionKey(SessionsLabels.TOKEN_KEY);
    return config;
    }, error => Promise.reject(error));

InstanceAxios.interceptors.response.use(response => response, error => {
    if (error.response.status === 403) {
        sessionOut();
    }
    return Promise.reject(error);
});

const Axios = () => InstanceAxios;
export default Axios