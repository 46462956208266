import Actions                                                         from "./Store/Actions";
import {enrollCourseUserRepo, login, postSignUp}                       from "../Repository/Requests";
import {getSessionKey, SessionsLabels, setSessionKey, validateSession} from "../Helpers/SessionStorage";
import {toast}                                                         from "react-toastify";
import {toastConfig, getLanguage}                                      from "../Helpers/MainHelper";
import {updatePlatform}                                                from "./PlatformsServices";
import Cookies                                                         from 'js-cookie';

export const userValidateSession = () => {
    const {language} = getLanguage();
  return async dispatch => {
    if (!validateSession())
      return dispatch({
        type:    Actions.LOAD_USER_INFO,
        payload: null
      });
    else {
      const user = getSessionKey(SessionsLabels.USER_KEY);
      await updatePlatform(language);
      dispatch({
        type:    Actions.LOAD_USER_INFO,
        payload: user
      })
    }
  }
}

export const getUserInfo = (data) => {
  return async dispatch => {
    dispatch({type: Actions.USER_LOADING});

    try {    
      const resp = await login(data);

      const {data: user} = resp;

      setSessionKey(SessionsLabels.PROFILE_IMAGE_URL, data.user.picture);
      setSessionKey(SessionsLabels.USER_KEY, user);
      setSessionKey(SessionsLabels.TOKEN_KEY, user.token);
      setSessionKey(SessionsLabels.ID_TOKEN, user.id_token);
      Cookies.set('SIGNIN_KEY', user.token);
      await validateNodeBB(user.nodebb_token);
      dispatch({
        type:    Actions.LOAD_USER_INFO,
        payload: user
      })
    } catch (error) {
      console.log(error);
      toast.error(`[${error.code}] ${error.message}`, toastConfig)
      dispatch({
        type:    Actions.LOAD_USER_INFO,
        payload: null
      })
    }
  }
}

const validateNodeBB = async nodebbToken => {
  const {REACT_APP_FORUMS_COOKIE: FORUMS_COOKIE, REACT_APP_FORUMS_COOKIE_DOMAIN: FORUMS_COOKIE_DOMAIN} = process.env;
  const cookieOpts = {domain: FORUMS_COOKIE_DOMAIN, path: '/'}
  const nodebb_cookie = Cookies.get(FORUMS_COOKIE, cookieOpts);

  if (nodebb_cookie === undefined) {
    Cookies.set(FORUMS_COOKIE, nodebbToken, cookieOpts);
  }
}

export const signUp = data => {
  return async () => {
    await postSignUp(data)
      .then(response => response)
      .catch(error => error);
  }
}

export const enrollCourseUserService = (courseID, data, token) => {
  try {
      return enrollCourseUserRepo(courseID, data, token);
  } catch (error) {
      console.log(error);
      return [];
  }
}