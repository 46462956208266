import Language from "./Languages/Language"
import moment   from "moment-timezone"

export const getLanguage = () => {
  let pathName        = window.location.pathname,
      defaultLanguage = navigator.language.split("-")[0];

  let splitByBasePath = process.env.REACT_APP_BASE_PATH !== '/' ? 2 : 1;

  let pathNameSkip = ["/getuserinfo", "/validateLogin", "/login", "/checkingprogress"];
  pathName = pathNameSkip.includes(pathName) ? '/' : pathName;

  let translation = pathName !== '/' ? pathName.split("/")[splitByBasePath].toUpperCase() : defaultLanguage.toUpperCase();

  // If doesn't exist the translation.
  translation = translation in Language ? translation : 'ES';

  return {
    translation: Language[translation],
    language:    'es'
  };
}

export const orderByDescending = (data, field = 'updated_at') => data.sort((a, b) => Number(moment(b[field]).valueOf()) - Number(moment(a[field]).valueOf()));

export const orderByAscending = (data, field = 'updated_at') => data.sort((a, b) => Number(moment(a[field]).valueOf()) - Number(moment(b[field]).valueOf()));

export const isEmailFormat = (email) => {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  return regex.test(email);
}

export const toastConfig = {
  position:        "top-right",
  autoClose:       7000,
  hideProgressBar: false,
  closeOnClick:    true,
  pauseOnHover:    true,
  draggable:       true,
}

export const getSearchParams = search => {
  const cleanParameters = search.slice(1, -1);
  const arrayParameter = cleanParameters.split("&");
  return arrayParameter.map(param => {
    const string = param.split("=");
    const label = string[0];
    const value = string[1];
    return {label, value}
  });
}