import {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import redirectPage from "../../assets/img/icon/redirect-page.svg";

export function AddEventListenerToLinkRedirect() {
    document.querySelectorAll('a').forEach(linkA => {
        linkA.addEventListener('click', e => HandleLinkEventClick(e, linkA));
    });
}

export function RemoveEventListenerToLinkRedirect() {
    document.querySelectorAll('a').forEach(linkA => {
        linkA.removeEventListener('click', e => HandleLinkEventClick(e, linkA));
    });
}

const HandleLinkEventClick = (e, linkA) => {
    const urlLink = new URL(linkA.href);
    const validationLinkDomian = !urlLink.hostname ? null : !urlLink.hostname.includes("tergar.org");
    if (validationLinkDomian && linkA.id !== 'redirectLinkTagA') {
    /*const validationLinkDomian = !urlLink.hostname ? null : urlLink.hostname.replace(urlLink.hostname.split('.')[0], '');
    if (validationLinkDomian && validationLinkDomian !== '.tergar.org' && linkA.id !== 'redirectLinkTagA') {*/
        e.stopPropagation();
        e.preventDefault();
        document.getElementById('buttonRedirectModal').click(); // Open modal with bootstrap jquery embebed
        setTimeout(() => document.getElementById('redirectLinkTagA').href = linkA.href, 500);
    }
}

export function RedirectModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" className="invisible" onClick={handleShow} id="buttonRedirectModal">redirect
                modal</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body style={{padding: "30px"}}>
                    <div className="text-center col-12">
                        <img src={redirectPage} style={{width: "95px", height: "65px"}}
                             alt="redirect-page-icon"/>
                    </div>
                    <div className="col-12 redirect-modal-title">You are now leaving Tergar</div>
                    <div className="col-12 redirect-modal-content">You are now leaving this Tergar website to an external site where you will be subject to that site's privacy policy.login</div>
                    <div className="col-12 row justify-content-around">
                        <Button variant="flat" onClick={handleClose} className="col-12 col-md-5">Back to Tergar</Button>
                        <a className="btn btn-call-action col-12 col-md-4" id="redirectLinkTagA" href="#valid"
                           target="_blank"
                           role="button">Go to site</a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}