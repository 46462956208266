const Actions = {
    /********* PLATFORMS *********/
    LOAD_PLATFORM: 'LOAD_PLATFORM',
    LOAD_PLATFORMS: 'LOAD_PLATFORMS',
    /********* ENROLLED COURSES *********/
    LOAD_ENROLLED_COURSES: 'LOAD_ENROLLED_COURSES',
    ERROR_ENROLLED_COURSES_NOT_FOUND: 'ERROR_ENROLLED_COURSES_NOT_FOUND',
    /********* COURSES *********/
    LOAD_COURSES: 'LOAD_COURSES',
    ERROR_COURSES_NOT_FOUND: 'ERROR_COURSES_NOT_FOUND',
    GET_COURSES: 'GET_COURSES',
    ERROR_COURSES: 'ERROR_COURSES',
    /********* USER *********/
    LOAD_USER_INFO: 'LOAD_USER_INFO',
    ERROR_USER_NOT_FOUND: 'ERROR_USER_NOT_FOUND',
    USER_LOADING: 'USER_LOADING',
    /********* CONFIGURATIONS *********/
    LOAD_CONFIGURATIONS: 'LOAD_CONFIGURATIONS',
    /********* SECTIONS *********/
    LOAD_SECTIONS_BY_COURSE: 'LOAD_SECTIONS_BY_COURSE',
    UPDATE_SECTION_ACTIVITY: 'UPDATE_SECTION_ACTIVITY',
    LOAD_SECTION: 'LOAD_SECTION',
    ERROR_LOAD_SECTION: 'ERROR_LOAD_SECTION',
    ERROR_SECTIONS_NOT_FOUND: 'ERROR_SECTIONS_NOT_FOUND',
    /********* RESOURCES *********/
    LOAD_RESOURCES_BY_COURSE: 'LOAD_RESOURCES_BY_COURSE',
    /********* MENU LINKS *********/
    LOAD_MENU_LINKS: 'LOAD_MENU_LINKS',
    /********* NOTES *********/
    LOAD_NOTES: 'LOAD_NOTES',
    CREATE_NOTE: 'CREATE_NOTE',
    UPDATE_NOTE: 'UPDATE_NOTE',
    DELETE_NOTE: 'DELETE_NOTE',
    UPDATE_STATUS: 'UPDATE_STATUS',
    ON_SELECT_NOTE: 'ON_SELECT_NOTE',
    NOTES_LOADING: 'NOTES_LOADING',
    /********* WEBINARS *********/
    LOAD_SUBSCRIBED_WEBINARS: 'LOAD_SUBSCRIBED_WEBINARS',
    ERROR_WEBINARS_NOT_FOUND: 'ERROR_WEBINARS_NOT_FOUND',
    UNSUBSCRIBED_WEBINARS: 'UNSUBSCRIBED_WEBINARS',
    LOAD_NEXT_WEBINARS: 'LOAD_NEXT_WEBINARS',
    ERROR_NEXT_WEBINARS_NOT_FOUND: 'ERROR_NEXT_WEBINARS_NOT_FOUND',
    LOAD_PREVIOUS_WEBINARS: 'LOAD_PREVIOUS_WEBINARS',
    ERROR_PREVIOUS_WEBINARS_NOT_FOUND: 'ERROR_PREVIOUS_WEBINARS_NOT_FOUND',
    REGISTER_WEBINARS: 'REGISTER_WEBINARS',
    /********* COURSE MARKETING INFORMATION *********/
    LOAD_COURSE_MARKETING_INFO: 'LOAD_COURSE_MARKETING_INFO',
    ERROR_COURSE_MARKETING_INFO_NOT_FOUND: 'ERROR_COURSE_MARKETING_INFO_NOT_FOUND',
}

export default Actions