import http  from "./AxiosConfig";
import axios from "axios";

/*****************   THESE ENDPOINTS ARE FOR SERVER USE ONLY *****************/

export const login = data =>
    axios.post(`${process.env.REACT_APP_API_URL}v1/sessions/login`, data);


export const loadPlatforms = () => {
    return http()
        .get(`v1/platforms`)
        .then(response => response)
        .catch(error => error.response);
};

export const getSectionsByCourseID = (courseID, userID) => {
    return http()
        .get(`v1/sections/by_course_and_student/${courseID}/${userID}`)
        .then(response => response.data)
        .catch(error => error.response.data);
};

export const getProgressBySection = (sectionID, userID) => {
    return http()
        .get(`v1/sections/${sectionID}/get_progress/${userID}`)
        .then(response => response.data)
        .catch(error => error.response);
};

export const loadWebsiteMenus = menuID => {
    return http()
        .get(`v1/website_menus/${menuID}/get_children`)
        .then(response => response.data)
        .catch(error => error.response);
};

export const loadCoursesByPlatform = (platformID) => {
    return http()
        .get(`v1/courses/by_platform/${platformID}`)
        .then(response => response.data)
        .catch(error => error.response);
}

export const getCourseById = (courseID) => {
    return http()
        .get(`v1/courses/${courseID}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const enrollCourseUser = (courseId, userInfo, token) => {
    return http()
        .post(`v1/enrollments/course/${courseId}`, userInfo, {
            headers: {Authorization: "Bearer " + token}
        })
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const loadEnrolledCoursesByUser = (userID) => {
    return http()
        .get(`v1/users/${userID}/enrolled_courses`)
        .then(response => response.data)
        .catch(error => error.response);
}

export const loadMenuLinks = (platformID) => {
    return http()
        .get(`v1/website_menus/by_platform/${platformID}`)
        .then(response => response.data)
        .catch(error => error.response);
}

export const deleteNoteById = (id) => {
    return http()
        .delete(`v1/notes/${id}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const updateNoteRepo = (id, data) => {
    return http()
        .put(`v1/notes/${id}`, data)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const createNoteRepo = (data) => {
    return http()
        .post(`v1/notes/`, data)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const loadNotesByPlatformRepo = (platformID, userID) => {
    return http()
        .get(`v1/notes/by_user_and_platform/${userID}/${platformID}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const loadConfigurationsByPlatform = (platformID) => {
    return http()
        .get(`v1/configurations/${platformID}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const loadEnrolledCoursesByLanguageRepo = (userID, platformID) => {
    return http()
        .get(`v1/users/${userID}/platforms/${platformID}/courses`)
        .then(response => response.data)
        .catch(error => error.response);
}

export const getMarketingInfoByCourse = (courseSlug) => {
    return http()
        .get(`v1/courseMarketingInfo/find-all-by-course/${courseSlug}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const getCourseBySlug = (courseSlug) => {
    return http()
        .get(`v1/courses/one_by_slug/${courseSlug}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const getSectionsBycourseSlug = (courseSlug) => {
    return http()
        .get(`v1/sections/by_course_slug/${courseSlug}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const getCountriesAPI = () => {
    return http()
        .get("https://restcountries.eu/rest/v2/all")
        .then(response => response.data)
        .catch(error => error);
}

export const postSignUp = data => {
    return http()
        .post(`v1/users`, data)
        .then(response => response)
        .catch(error => error.response);
}

export const updateSectionsActivitiesRepo = (data) => {
    return http()
        .post(`v1/userActivities`, data)
        .then(response => response.data)
        .catch(error => console.error(error.response.data));
}

export const getSectionByCourse = sectionID => {
    return http()
        .get(`v1/sections/${sectionID}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const findNextByCourse = courseID => {
    return http()
        .get(`v1/webinars/find-next-by-course/${courseID}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const getResourcesByCourse = courseID => {
    return http()
        .get(`v1/resources/by_course/${courseID}`)
        .then(response => response.data)
        .catch(error => error.response);
}

export const getSubscribedWebinarsByPlatform = (userID, platformID) => {
    return http()
        .get(`v1/users/${userID}/get_subscribed_webinars_by_platform/${platformID}`)
        .then(response => response.data)
        .catch(error => error.response.data);
}

export const enrollCourseUserRepo = (courseID, data, token) => {
    return axios
    .post(`${process.env.REACT_APP_API_URL}v1/enrollments/course/${courseID}`, data, {
            headers: {Authorization: "Bearer " + token}
        })
        .then(response => response.data)
        .catch(error => error.response.data);
}
