import { isEmpty } from "lodash";
import Cookies from "js-cookie";

const storage = window.localStorage;

export const SessionsLabels = {
  USER_KEY: "USER_KEY",
  TOKEN_KEY: "TOKEN_KEY",
  ID_TOKEN: "ID_TOKEN",
  PLATFORM_ID: "PLATFORM_ID",
  FROM_URL: "FROM_URL",
  PROFILE_IMAGE_URL: "PROFILE_IMAGE_URL",
};

export const getSessionKey = (key) => {
  let sessionKey = key in storage ? storage.getItem(key) : "";
  return isEmpty(sessionKey) || sessionKey === "undefined"
    ? ""
    : JSON.parse(sessionKey);
};

export const setSessionKey = (key, data) => {
  // Validate session key in storage
  if (!isEmpty(storage.getItem(key))) storage.removeItem(key);

  storage.setItem(key, JSON.stringify(data));
};

export const removeSessionKey = (key) => {
  if (!isEmpty(storage.getItem(key))) storage.removeItem(key);
};

export const validateSession = () => {
  const user = getSessionKey(SessionsLabels.USER_KEY);
  return !isEmpty(user);
};

export const sessionOut = () => {
  storage.clear();

  const {
    REACT_APP_AUTH0_COOKIE_DOMAIN,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_REDIRECT_LOGOUT,
    REACT_APP_AUTH0_CLIENT_ID,
  } = process.env;

  const cookieOpts = { domain: REACT_APP_AUTH0_COOKIE_DOMAIN, path: "/" };

  //Clear cookies
  Cookies.remove("tergar_user_auth_access_token", cookieOpts);
  Cookies.remove("tergar_user_auth_id_token", cookieOpts);
  Cookies.remove("tergar_user_auth_expire_time_token", cookieOpts);
  Cookies.remove("wp_user_a0Id", cookieOpts);

  //Get logout link to with id token in auth0
  let logoutLink = `https://${REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${REACT_APP_AUTH0_REDIRECT_LOGOUT}&client_id=${REACT_APP_AUTH0_CLIENT_ID}`;
  window.location.href = logoutLink;
};

export const ssoSloVerifySession = (isAuthenticated) => {

  if(!isAuthenticated){
    return sessionOut();
  }
  /*const accessToken = Cookies.get("tergar_user_auth_access_token");
  const expireTime = Cookies.get("tergar_user_auth_expire_time_token");
  const currentTime = moment().unix();

  if (!isEmpty(accessToken) && !isEmpty(expireTime) && currentTime < parseInt(expireTime) && !validateSession()) {
    return <Navigate to="/login" />;
  } else if ((isEmpty(accessToken) || currentTime > parseInt(expireTime)) && validateSession()) {
    return sessionOut();
  }*/
};
