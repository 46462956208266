import styled, { createGlobalStyle } from "styled-components";
import Bkwhithe                      from "../img/var/bk-white-offset.gif";
import CloseIcon                     from "../img/icons/close.svg";
import GoBackIcon                    from "../img/icons/back.svg";
import DeleteIcon                    from "../img/icons/delete.svg";
import DeleteBlueIcon                from "../img/icons/delete-blue.svg";
import DownloadIcon                  from "../img/icons/download.svg";
import DownloadBlueIcon              from "../img/icons/download-blue.svg";
import FullScreenIcon                from "../img/icons/full-screen.svg";
import ResizeScreenIcon              from "../img/icons/resize-screen.svg";
import ConfirmDeleteIcon             from "../img/icons/warning.svg";
import ConfirmSavedIcon              from "../img/icons/check.svg";
import MenuRiseNotes                 from "../img/icons/notes.svg";
import MenuRiseAppTergar             from "../img/icons/tergar-app.svg";
import BkGradientBanner              from "../img/var/bk-overlay-black-banner-course.png";
import BkLineGray                    from "../img/var/bk-gray-line.gif";
import BKHistoryWebinarFirst         from "../img/var/bk-history-webinar-first.png";
import BKHistoryWebinarEnd           from "../img/var/bk-history-webinar-end.png";
import BKHistoryWebinar              from "../img/var/bk-history-webinar.png";
import NoWebinar                     from "../img/var/no-webinar.png";
import BulletOrange                  from '../img/icons/bullet.svg';
import CopaFree                      from '../img/icons/copa.svg';
import CopaPaga                      from '../img/icons/copa-paga.svg';

export const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    color: #353535;
    line-height: 1.62857143;
    height: 100%;
  }

  #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .h100 {
    height: 100%;
    position: relative;
  }

  @media (min-width: 1620px) {
    .container-fluid {
      width: 1500px;
    }
  }

  .contract-navigation div {
    width: 0;
    overflow: hidden;
  }

  .push {
    height: 50px;
  }

  .expand-message {
    padding: 12px 30px;
    -moz-transition: width .1s, height 1s, padding-top 1s, padding-bottom 1s !important;
    -ms-transition: width .1s, height 1s, padding-top 1s, padding-bottom 1s !important;
    -o-transition: width .1s, height 1s, padding-top 1s, padding-bottom 1s !important;
    -webkit-transition: width .1s, height 1s, padding-top 1s, padding-bottom 1s !important;
    transition: width .1s, height 1s, padding-top 1s, padding-bottom 1s !important;
  }

  select:disabled {
    opacity: 0.7;
  }

  .contract-message {
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;

    -moz-transition: width .3s, height .3s, padding-top .3s, padding-bottom .3s !important;
    -ms-transition: width .3s, height .3s, padding-top .3s, padding-bottom .3s !important;
    -o-transition: width .3s, height .3s, padding-top .3s, padding-bottom .3s !important;
    -webkit-transition: width .3s, height .3s, padding-top .3s, padding-bottom .3s !important;
    transition: width .3s, height .3s, padding-top .3s, padding-bottom .3s !important;
  }

  .sidebar-rise-collapse {
    width: 68px !important;
  }

  .sidebar-rise-collapse .hidden-collapse {
    width: 0;
    overflow: hidden;
    display: inline-block;
    transition: all 300ms ease-in-out;
    height: 0;
  }

  .sidebar-rise-collapse .fa-chevron-left::before {
    content: "\f054";
  }

  .sidebar-rise-expand {
    transition: all 300ms ease-in-out;
  }

  .content-rise-collapse {
    width: calc(100% - 68px) !important;
    transition: all 300ms ease-in-out;
  }

  .content-rise-expand {
    transition: all 300ms ease-in-out;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-menu > li > ul.dropdown-menu, .dropdown-menu > li > ul.dropdown-menu {
    left: 100% !important;
    top: 4px;
    min-width: 224px;
  }

  .dropdown-menu > li > ul.dropdown-menu > li > a {
    padding: 4px 14px;
  }

  .dropdown-menu > li > .dropdown-toggle, .dropdown-menu > li > .dropdown-toggle:hover {
    background: url(https://s3-eu-central-1.amazonaws.com/tergarlmsmlbucket/wp-content/uploads/2021/10/28224650/dropdown.png) 96% center/6px no-repeat !important;
  }

  .dropdown-menu > li > .dropdown-toggle:hover, .dropdown-menu > li > .dropdown-toggle:focus, .dropdown-menu > li > .dropdown-toggle:hover, .dropdown-menu > li > .dropdown-toggle:focus {
    background-color: #f4f4f4;
  }

  .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
  }

  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    border-radius: 0 4px 4px;
  }

  .dropdown-submenu > .dropdown-toggle {
    padding-left: 34px;
  }

  @media (min-width: 992px) {
    .dropdown-submenu:hover > .dropdown-menu {
      display: block !important;
    }
  }

  @media (max-width: 991px) {

    .dropdown-menu > li > ul.dropdown-menu, .dropdown-menu > li > ul.dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .tab-content > .divisor {
      display: none
    }

    .courses-tabs > .tab-content > .tab-pane {
      padding-top: 20px
    }

    .webinars-row {
      padding: 0 15px
    }

  }

  @media (min-width: 1620px) {
    .container-fluid {
      width: 1500px;
    }
  }

  @media (max-width: 767px) {
    .sidebar-rise-collapse {
      left: -68px;
    }

    .sidebar-rise-collapse > a {
      left: 100%;
      border-radius: 0 10px 10px 0;
    }
  }

  @media (min-width: 768px) {
    .mt0 {
      margin-top: 0;
    }
  }

  /*********************************** DEFAULT **************************************/
  .Toastify__progress-bar--default {
    background: #4caa2b !important;
  }

  /*********************************** INFO **************************************/
  .Toastify__toast--info {
    background: #fff !important;
  }

  .Toastify__close-button--info {
    color: #000 !important;
    opacity: .3 !important;
  }

  .Toastify__progress-bar--info {
    background: #4caa2b !important;
  }

  /*********************************** SUCCESS **************************************/
  .Toastify__toast--success {
    background: #fff !important;
  }

  .Toastify__close-button--success {
    color: #000 !important;
    opacity: .3 !important;
  }

  .Toastify__progress-bar--success {
    background: #4caa2b !important;
  }

  /*********************************** WARNING **************************************/
  .Toastify__toast--warning {
    background: #fff !important;
  }

  .Toastify__close-button--warning {
    color: #000 !important;
    opacity: .3 !important;
  }

  .Toastify__progress-bar--warning {
    background: #ffbe26 !important;
  }


  /*********************************** WARNING **************************************/
  .Toastify__toast--error {
    background: #fff !important;
  }

  .Toastify__close-button--error {
    color: #000 !important;
    opacity: .3 !important;
  }

  .Toastify__progress-bar--error {
    background: #c4243d !important;
  }

  .menu-slide {
    @media (max-width: 456px) {
      -ms-overflow-style: none;
      overflow-y: scroll;
    }
  }

  .courses-tabs > div > .nav-pills {
    padding-right: 0 !important;
  }

  .courses-tabs > div > .tab-content {
    padding: 0 !important;
  }

  .courses-tabs > div > .tab-content > .tab-pane.active > ul > li {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }

  .courses-tabs > div > .nav-pills > li > a {
    color: #666;
    padding: 10px 15px;
  }
  
  .title-category-webinar {
    font-size: 18px; 
    font-family: 'Barlow', sans-serif; 
    font-weight: 500;
  }
  
  .recording-webinar {
    border-bottom: 1px dashed #D7D7D7; 
    padding-bottom: 25px; 
    padding-top: 30px; 
    position: relative;
  }
  
  .recording-title {
    font-size: 16px; 
    font-weight: 400; 
    margin: 2px 0 2px; 
    font-family: 'Open Sans';
  }
  
  .courses-tabs > div > .nav-pills > li {
     text-transform: uppercase; 
     float: none; 
     border-bottom: 1px dashed #ccc; 
     margin-bottom: 0; 
     font-family: 'Open Sans';
  }
  
  .recording-desc {
     font-family: 'Barlow', sans-serif;
     font-weight: 300;
  }
  
  .modal-body-record {
    flex: 1 1 auto;
    padding: 0;
    position: relative;
  }

  .courses-tabs > div > .nav-pills > li.active > a, .courses-tabs > div > .nav-pills > li.active > a:focus, .courses-tabs > div > .nav-pills > li.active > a:hover {
    color: #666;
    background-color: #eee;
  }

  .tab-content > .divisor {
    width: 1px;
    height: 100%;
    position: absolute;
    background: #ddd;
    margin-left: -1px;
  }

  .mb-contitional{
    margin-bottom: 2.25rem!important;
  }
  .logo{
    max-height:100px;
  }

  .bg-gray h2{
    font-size:16px;
    color: #676a6c;
  }

  .btnLogin{
    background: #1ab394;
    color:#fff;
    display:inline-block;
    padding:10px 20px;
    border-radius:6px;
    margin-bottom:20px;
    border:none;
  }

  .btnLogin:hover{
    text-decoration: none;
    color: #ffffffbf;
  }

  .bg-gray{
    background: #f3f3f4;
    padding-top:50px;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /*body, html{
    background: #f3f3f4;
  }*/

  .mb20{
    margin-bottom:20px;
  }

  small{
    color:#676a6c;
  }

  .start a:hover, .start a:focus{background:transparent !important; cursor: default !important;}
  .inden-niv1 {text-indent: 14px;}
  .inden-niv2 { text-indent: 28px;}

  .main-menu .divider {margin: 4px 0 !important;}


  @media (max-width: 991px) {
    #menu-main-menu > .divider {height: 1px; margin: 3px 0; overflow: hidden; background-color: #e5e5e5;}
    .botones-centro {width:100%; text-align:center; margin:14px 0 0 !important;}
    .botones-centro a {max-width: 80%; margin: 10px auto !important;}
    .iniciar-se a {border: 1px solid #353535; border-radius:4px;}
  }

  @media (max-width: 767px) {
  }

  .linea-curso2021-inicio::after{content:''; left: 33px; top: 58px; bottom: 0; position: absolute; width: 1px; background-color: #e39f24;}
  .linea-curso2021-intermedio::after{content:''; left: 33px; top: 0; bottom: 0; position: absolute; width: 1px; background-color: #e39f24;}
  .linea-curso2021-final::after{content: ''; left: 33px; top: 0px; height: 60px; position: absolute; width: 1px; background-color: #e39f24;}

  .btn-return-to{font-size: 12px!important;}
  .btn-return-to a{color: #337ab7!important;font-size: 12px!important; margin-left: 1px!important; display: inline;}
  .bi {font-size: 13px}
  .h1, .h2, .h3, h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .accordion-item {
    border: none;
    background-color: transparent;
  }
  .accordion-item h2 {
    margin: 0;
  }
  .accordion-button {
    padding: 8px 13px;
    border-radius: 50px !important;
    background-color: transparent;
  }
  
  .h80 {height: 80vh}
  .sidebar-rise-collapse .pd-off button > span{padding-left: 15px}
  .sidebar-rise-collapse a.link-sidebar {padding-left:10px}
  .sidebar-rise-collapse .BkSideBarRise {padding: 35px 15px 50px;}


/**************************************
*     Redirect Modal Styles
**************************************/
.btn-flat {font-family: 'Open Sans', sans-serif !important;display: inline-block; padding: 8px 20px 5px; text-transform: uppercase; font-size: 15px; background:transparent !important; color:#E39F24; letter-spacing: 1px; line-height:1.42857143; font-weight: 600; border: none;}
.redirect-modal-title {font-family: 'Open Sans', sans-serif;font-style: normal;font-weight: 600;font-size: 20px;line-height: 27px;text-align: center;color:#353535;padding-bottom: 8px; padding-top: 20px;}
.redirect-modal-content {font-family: 'Open Sans', sans-serif;font-style: normal;font-weight: 400;font-size: 16px;line-height: 22px;text-align: center;color: #353535;padding-bottom: 20px;}
.btn-flat:hover {color:#E39F24}
.btn-call-action{display: inline-block; padding: 8px 20px 5px; text-transform: uppercase; background:#e39f24; color:#fff !important; letter-spacing: 1.6px; font-weight: 600; transition: all 300ms ease-in-out; border-bottom: 3px solid transparent; border-radius: 4px; font-family: 'Open Sans', sans-serif !important; font-size: 15px; line-height:1.42857143;}
.btn-call-action:hover, .btn-call-action:focus{border-bottom: 3px solid #ca8201; color: #fff; text-decoration: none;}
`;

export const Wrapper = styled.div`
  height: 100%;
  margin-bottom: -50px;

  @media (max-width: 991px) {
    margin-bottom: -36px;
  }
`;

export const WrapperAddMarginLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const WrapperAddMargin = styled.div`
  margin: 0 60px;
  font-weight: 300;

  .mt0 {
    margin-top: 0px;
  }

  @media (max-width: 991px) {
    margin: 0 20px;

    .mb30 {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 667px) {
    margin: 0;
  }
`;

export const TopBarMenu = styled.div`
  border-bottom: 1px solid #d8d8d8;
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
  color: #727070;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .menus-left-top li {
    display: inline-block;
    vertical-align: middle;
  }

  ul li a {
    color: #727070;
    padding: 6px;
    display: block;

    &:hover {
      text-decoration: none;
      opacity: 0.75;
    }

    &:focus {
      text-decoration: none;
    }
  }

  .glyphicon {
    margin-right: 4px;
  }

  .dropdown-menu a {
    font-size: 12px;
  }

  .dropdown-menu .divider {
    margin: 3px 0 !important;
  }

  .navbar-right {
    float: right !important;
  }

  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .dropdown-menu {
    padding: 0!important;
  }
  .dropdown-divider {
    margin: 2px!important;
  }
  .dropdown-item {
    color: rgb(38, 38, 38);
    font-weight: 300;
  }
  .nav-link {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }

/* TOPBAR MENU
--------------------------------------------------------------------------------------------- */
.full-width{
  width: 100% !important;
}

.topbar-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px;
}

.navbar {
    width: 100%;
    background-color: white;
}

.navbar .container-fluid {
    display: flex;
    justify-content: flex-end;
}

.nav-dropdown .dropdown-menu {
    // position: relative;
    width: 220px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    color: #424A58
}

.nav-dropdown .dropdown-menu .nav-link {
    display: flex;
    align-items: center;
    padding: 10px 21px;
    text-decoration: none;
    color: #424A58;
    font-size: 12px;
    font-family: 'Nunito Sans', 'sans-serif';
}

.nav-dropdown .dropdown-menu .nav-link:hover {
    background-color: #f8f9fa!important;
}
.nav-dropdown .dropdown-menu .nav-link:active {
    background-color: #f8f9fa!important;
}

.nav-dropdown .dropdown-menu .nav-link span {
    margin-right: 10px;
}

.nav-dropdown .dropdown-menu .offering-btn button {
	display: block;
	width:220px;
	text-align: left!importtant;
	padding: 10px 10px 8px;
	font-weight: 700;
	border:none;
	border-top: 1px solid #d8d8d8;
	background: transparent;
	font-size:12px;
  border-radius: 0!important;
}

.nav-dropdown .dropdown-menu .offering-btn img {
  margin-top:-2px;
}

.accordion .accordion-item {
  border: none;
}

.accordion .accordion-header {
  padding: 0;
}

.accordion .accordion-body {
  padding: 0;
}

.menu-offering {
  list-style: none;
  padding: 0;
}

.menu-offering .list-group-item {
  padding: 0;
}

.menu-offering .nav-link {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #000;
}

.menu-offering .nav-link:hover {
  background-color: #f8f9fa;
}

.nav-dropdown .nav-link.logout {
  color: red;
}

.nav-dropdown .nav-link.logout:hover {
  background-color: #f8f9fa;
}

.dropdown-menu .material-symbols-outlined,
.dropdown-menu .custom-icon{
  margin-right: 12px;
  font-size: 16px;
  vertical-align: middle;
  margin-top: -2px;
}

/* MENU OFFERING
--------------------------------------------------------------------------------------------- */

.accordion-button:not(.collapsed){
  color: #424A58;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:focus{
  border-color: transparent;
  box-shadow: none;
}

.accordion-button:hover{
  background-color: transparent;
}

.accordion-button::after{
  content: '';
  background: url(https://tergarassets.s3.us-east-2.amazonaws.com/svg/topbar-menu/caret-down.svg) center;
  display: inline-block !important;
  width: 10px;
  height: 9px;
  border: none;
  vertical-align: middle;
  margin: -4px 0 -3px 8px;
}

.menu-offering,
.menu-profile{
	border: none;
	padding: 0;
	border-radius:5px !important;
	overflow:hidden;
}

.menu-offering li,
.menu-profile li{
	margin-bottom:6px;
}

.menu-offering li:last-child,
.menu-profile li:last-child {
	margin-bottom: 0;
}

.menu-offering > li > a {
	padding: 8px 22px !important;
}

.menu-offering a::before {
	display: inline-block;
	content: '';
	height: 16px;
	width: 4px;
	vertical-align: middle;
	margin: -2px 12px 0 0px
}

.offering-tergarorg a::before {
	background-color: #AC141F;
}

.offering-vajrayana a::before {
	background-color: #1F8AED;
}

.offering-joyofliving a::before {
	background-color: #e39f24;
}

.offering-meditationteacher a::before {
	background-color: #8495DD;
}

.offering-tergarevents a::before {
	background-color: #91BFB2;
}

.menu-offering .list-group-item{
  border: none;
}
/* MENU OFFERING
--------------------------------------------------------------------------------------------- */

/* CUSMTOM ICONS
--------------------------------------------------------------------------------------------- */
.custom-icon{
  width: 16px;
  height: 16px;
  display: inline-block;
  position:relative;
}

.custom-icon::before {
  content:'';
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition:var(--transition-04s);
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.custom-icon::before {
  opacity:1;
}

.my-path::before {
    background-image: url(https://tergarassets.s3.us-east-2.amazonaws.com/path-blue.svg);
}
/* CUSMTOM ICONS
--------------------------------------------------------------------------------------------- */
/* DONATE TOP
--------------------------------------------------------------------------------------------- */
.divider-donate {
	display:inline-block;
	padding-right: 15px;
	position:relative;
}

.divider-donate::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 3px;
	background-color: #bababa;
	display: block;
	width: 1px;
	height: 17px;
	margin-top: -8.5px;
}

.link-donate {
  display: block;
  text-decoration: none;
  color: #ac141f;
  font-weight: 600;
  font-size:12px;
  padding: 0.25rem 0;
}

.link-donate:hover, .link-donate:focus  {
  background:transparent !important;
  color: #ac141f;
  text-decoration: none;
}
/* DONATE TOP
--------------------------------------------------------------------------------------------- */
@media (min-width: 768px) {
    #basic-nav-dropdown .dropdown-menu {
        right: 0;
        left: auto;
    }
}
  
.gravatar-picure{
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 100%;
  vertical-align: middle;
  margin: 0 8px 0 0;
}

.gravatar-name{
  display: none;
}

.dropdown-toggle::after{
  display: none!important;
}

@media (min-width: 992px) {
  .gravatar-name{
    display: inline;
    font-size: 13px!important;
    font-weight: 600 !important;
    font-family: 'Nunito Sans', 'sans-serif';
  }
  .dropdown-toggle::after{
    content: '';
    background: url(https://tergarassets.s3.us-east-2.amazonaws.com/svg/topbar-menu/caret-down.svg) center;
    display: inline-block !important;
    width: 10px;
    height: 9px;
    border: none;
    vertical-align: middle;
    margin: -4px 0 0 8px;
  }
}
`;

export const MenuLMS = styled.div`
  .navbar {
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    transition: all 300ms ease-in-out;
    padding-top: 0;
    padding-bottom: 0;
  }

  .nav-link {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .navbar-header {
    @media (max-width: 991px) {
      float: none;
    }
  }

  .navbar-collapse {
    background-color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 13.5px !important;

    @media (max-width: 991px) {
      text-align: left;
    }
  }

  .navbar-collapse .navbar-nav .dropdown-divider {
    margin: 2px !important;
  }

  .navbar-collapse .navbar-nav {
    margin-top: 9px;
  }

  .navbar-brand {
    display: inline-block;
    height: auto;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;

    @media (max-width: 991px) {
      float: none;
      display: inline-block;
      padding: 2px;
    }

    @media (max-width: 767px) {
      float: left;
    }
  }

  .navbar-brand img {
    height: 54px;
    width: auto;
    @media (max-width: 1199px) {
      height: 42px;
    }
    @media (max-width: 767px) {
      height: 45px;
      margin-left: 16px;
    }
  }

  .navbar-collapse .navbar-nav > .nav-item > a {
    color: #353535;
  }

  .navbar-collapse .navbar-nav > .nav-item.show .nav-link {
    padding: 2px 8px;

    @media (max-width: 991px) {
      margin-top: 2px;
      margin-bottom: 4px;
      line-height: 1.62857143;
    }
  }

  .navbar-collapse .navbar-nav > a.nav-link,
  .navbar-collapse .navbar-nav > .nav-item,
  .navbar-collapse .navbar-nav > .show > a {
    color: #353535;
    letter-spacing: 1px;
    transition: all 300ms ease-in-out;
    padding: 6px 3px;
    background: transparent;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: #777;
    }

    &:focus {
      text-decoration: none;
      color: #777;
    }

    @media (max-width: 991px) {
      margin: 0 5px;
    }
  }

  .navbar-collapse .navbar-nav > .show > a {
    @media (max-width: 991px) {
      background-color: #f4f4f4;
    }
  }

  .navbar-collapse .navbar-nav .dropdown-menu {
    padding: 0.2rem 1rem;
    letter-spacing: 0;
    text-transform: none;
    font-size: 13.5px;
    min-width: 14rem;
  }

  .navbar-collapse .navbar-nav > .dropdown-menu.show::before {
    box-shadow: none;
  }

  .navbar-collapse .navbar-nav > .nav-item.show > .dropdown-menu.show > a.nav-link {
    padding: 3px 0 !important;
  }

  .navbar-collapse .navbar-nav .nav-item.show .dropdown-menu.show {
    @media (max-width: 991px) {
      border: none;
    }
  }

  .navbar-right {
    margin-top: 30px;

    @media (max-width: 991px) {
      float: none !important;
      display: inline-block;
      margin-top: -5px;
    }

    @media (max-width: 767px) {
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  .navbar-collapse .navbar-nav .nav-link::before,
  .navbar-collapse .navbar-nav > .nav-item::before {
    content: "";
    left: 0;
    bottom: 0;
    width: 0%;
    height: 2px;
    position: absolute;
    background: #e39f24;
    transition: all 300ms ease-in-out;
  }

  .navbar-collapse .navbar-nav > a.nav-link:hover::before,
  .navbar-collapse .navbar-nav > .nav-item:hover::before,
  .navbar-collapse .navbar-nav > .active > a::before,
  .navbar-collapse .navbar-nav > .show > a::before,
  .navbar-collapse .navbar-nav > .show > a:focus::before {
    width: 100%;
  }

  .navbar-collapse .navbar-nav > a.nav-link:last-child {
    color: #e39f24;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #e39f24;
    margin-left: 20px;
    padding: 5px 12px;
    height: 34px;

    &:hover {
      border-color: #e39f24;
      background-color: transparent;
      background-color: #e39f24;
      color: #fff;
    }

    &:focus {
      border-color: #e39f24;
      background-color: transparent;
      background-color: #e39f24;
      color: #fff;
    }

    @media (max-width: 767px) {
      width: 80%;
      margin: 10px auto 25px;
      text-align: center;
    }
  }

  .navbar-collapse .navbar-nav > a.nav-link:last-child::before {
    height: 0;
  }

  .dropdown-menu > a.nav-link {
    text-transform: initial;
    font-weight: 300;
  }

  #menu-main-menu .dropdown-menu {
    right: auto !important;
    left: 0 !important;
  }

  .navbar-nav > .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0 8px 0 5px;
  }

  @media (min-width: 768px) {
    .navbar-right .dropdown-menu {
      right: initial !important;
    }
  }
`;

export const BtnGold = styled.a`
  display: inline-block !important;
  border: 1px solid #e39f24;
  border-radius: 4px;
  padding: 5px 8px !important;
  color: #e39f24 !important;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  font-size: 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e39f24 !important;
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);
    text-decoration: none;
  }
`;

export const FooterSite = styled.footer`
  background-color: #f2f2f2;
  text-shadow: 1px 1px 0 #fff;
  font-size: 13px;
  width: 100%;
  height: 50px;
  clear: both;

  @media (max-width: 991px) {
    height: 36px;
  }

  .copy-page {
    padding: 14px 0 0;
    margin: 0;
    @media (max-width: 991px) {
      padding: 6px 0 0;
      text-align: center;
    }
  }

  a {
    color: #353535;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-family: "Open Sans", sans-serif;
    text-align: center;

    &:hover {
      opacity: 0.75;
    }

    &:focus {
      opacity: 0.75;
    }
  }

  .social-link-foot {
    color: #e4a025;
    width: 48px;
    height: 50px;
    background-color: transparent;
    border: none;
  }
`;

export const FooterSiteRise = styled.footer`
  background-color: #f2f2f2;
  text-shadow: 1px 1px 0 #fff;
  font-size: 13px;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 50px;
  clear: both;
  @media (max-width: 991px) {
    height: 36px;
  }
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    z-index: 10;
  }
  .copy-page {
    padding: 14px 0 0;
    margin: 0;
    @media (max-width: 991px) {
      padding: 6px 0 0;
      text-align: center;
    }
  }
  a {
    color: #353535;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    &:hover {
      opacity: 0.75;
    }
    &:focus {
      opacity: 0.75;
    }
  }
`;

export const TitleH2 = styled.h2`
  font-family: "Cormorant Garamond", serif;
  font-size: 44px;
`;

export const TabControl = styled.div`
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  margin-top: 30px;
  margin-bottom: 30px;

  .nav-tabs.nav-justified > li > a {
    border: none;
    border-bottom: 2px solid transparent;
    font-weight: 300;
    color: #353535;
  }

  .nav > li > a:focus,
  .nav > li > a:hover {
    background: transparent;
    font-weight: 400;
  }

  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:focus,
  .nav-tabs.nav-justified > .active > a:hover {
    border: none;
    border-bottom: 2px solid #e39f24;
    font-weight: 400;
  }
`;

export const BtnBlue = styled.a`
  margin-top: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  background: #477ca0;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: 1px;
  padding: 5px 16px;
  transition: all 300ms ease-in-out;
  text-decoration: none;

  &:hover {
    background: #5dade2;
    color: #fff;
    text-decoration: none;
  }
`;

export const BtnGray = styled.a`
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: #353535;
  background: #f7f7f7;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: 1px;
  padding: 5px 16px;
  transition: all 300ms ease-in-out;
  text-decoration: none;

  &:hover {
    background: #d1d3d7;
    text-decoration: none;
    color: #353535;
    text-shadow: 1px 1px 0 #fff;
  }
`;

export const ContCourse = styled.div`
    border: 1px solid #d8d8d8;
    border-top-width:12px;
    overflow: hidden;
    margin:15px 0;
    padding-bottom: 12px;
    position:relative;

    img{
        max-width: 180%;
        margin: 0 0 0px -40%;
    }

    h2{
        font-family: 'Cormorant Garamond', serif;
        font-size: 26px;
        font-weight: 300;
        padding: 10px 22px 0;
    }

    h5{
        padding: 2px 22px;
        text-transform:uppercase;
        margin-bottom: 2px;
        font-weight: 300;
        font-family: 'Open Sans', sans-serif;
    }

    p{
        opacity: 0.8;
        font-size: 12px;
        padding: 0 22px;
        margin-bottom: 2px;
        text-align: right;
    }

    a{
        display:block;
        text-align: center;
        margin: 12px 22px;
    }
`;

export const WrapperCourses = styled.div`
  padding: 10px 0 24px;
  display: flex;
  flex-direction: row;

  .gold {
    border-top-color: #e39f24;
  }
  .green {
    border-top-color: #8cc3bb;
  }
  .red {
    border-top-color: #f48975;
  }
  .blue-free {
    border-top-color: #23527C
  }
  
  .mr-20 {
    margin-right: 20px;
  } 
`;

export const BkGrayTop = styled.div`
  background-image: url(${Bkwhithe});
  background-color: #f7f7f7;
  background-position: center right;
  background-size: 60px;
  background-repeat: repeat-y;
  padding: 20px 0 15px;
  margin-bottom: 60px;
  float: left;
  width: 100%;
  position: relative;
  z-index: 1;

  @media (max-width: 767px) {
    background-image: none;
    padding-bottom: 30px;
  }
`;

export const BtnNavigationSideBar = styled.a`
  transition: all 300ms ease-in-out;
  background: #477ca0;
  color: #fff;
  border-bottom-right-radius: 10px;
  padding: 6px 12px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: #fff;
    text-decoration: none;
    background: #5193c4;
  }

  @media (max-width: 767px) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    height: 32px;
    width: 32px;
    padding: 5px 6px;
    position: absolute;
    z-index: 1;
  }
`;

export const SideBarRise = styled.div`
  overflow: hidden;
  width: 250px;
  background: #f7f7f7;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  transition: all 300ms ease-in-out;
  padding: 0;

  @media (max-width: 991px) {
    width: 230px;
  }

  @media (max-width: 767px) {
    position: absolute;
    overflow: visible;
    z-index: 2;
    width: 100%;
  }
  
  a{
    text-decoration: none;
  }

  .nav-link.sections-link {
    font-size: 14px;
    color: rgb(53, 53, 53);
    padding: 0!important;
    margin-bottom: 8px;
  }
  .accordion-button {
    color: rgb(53, 53, 53);
    font-weight: 300;
  }
  
  .accordion-button:hover {
    background: rgba(100,100,100,0.15);
    color: #222;
  }

  .current {
    color: #e4a025!important;
  }

  .completed {
    opacity: 0.5;
  }
`;

const BkSideBar = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 300ms ease-in-out;
`;
export const BkSideBarRise = styled(BkSideBar)`
  background-color: #f7f7f7;
  padding: 35px 4px 50px;

  @media (max-width: 767px) {
    padding: 25px 15px 30px;
  }
`;

export const BkSideBarRiseWebinar = styled(BkSideBar)`
  background-color: #fff;
  padding: 25px 15px 30px;
  display: ${props => props.showWebinars ? 'block' : 'none'};

  @media (max-width: 767px) {
    background-color: #f7f7f7;
    padding: 0px 15px 30px;
  }
`;

export const MenIconSideBar = styled.a`
    text-transform: uppercase;
    color: #353535;
    border-radius: 30px;
    display:block;
    padding: 4px 10px 5px;
    overflow: hidden;
    height: 37px;
    transition: all 300ms ease-in-out;

    span{
      display: ${(props) => (props.collapsed ? "none" : "inline-block")};
      vertical-align: middle;
    }

    &:hover{
      background: rgba(100,100,100,0.15);
      color:#222;
      text-decoration:none;
    }

    &:focus{
      color:#222;
    }
`;

export const MenIconSideBarLink = styled.div`
    a {
      text-transform: uppercase;
      color: #353535;
      border-radius: 30px;
      display:block;
      padding: 4px 10px 5px;
      overflow: hidden;
      height: 37px;
      transition: all 300ms ease-in-out;

      &:hover{
        background: rgba(100,100,100,0.15);
        color:#222;
        text-decoration:none;
      }
  
      &:focus{
        color:#222;
      }
    }

    a > span{
      display: inline-block;
      vertical-align: middle;
    }
`;

export const IconMenuRise = styled.img`
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`;

export const MenTakeNoteSideBar = styled.a`
    text-transform: uppercase;
    color: #fff;
    border-radius: 30px;
    background:url(${MenuRiseNotes}) 12px center/13px #487da5 no-repeat;
    display:block;
    padding: 4px 10px;
    transition: all 300ms ease-in-out;
    margin-top: 20px;
    overflow: hidden;
    height:37px;
    text-align: center;
    
    span{
        display: inline-block;
        vertical-align: middle;
        padding: 1px 30px;
        min-width:142px;
    }

    &:hover{
        background-color: #5193c4;
        color:#fff;
        text-decoration:none;
    }

    &:focus{
        color:#222;
    }
`;

export const MenMyPracticeSideBar = styled.a`
    text-transform: uppercase;
    color: #fff;
    border-radius: 30px;
    background:url(${MenuRiseAppTergar}) 9px center/19px #5c6bc0 no-repeat;
    display:block;
    padding: 4px 10px;
    transition: all 300ms ease-in-out;
    margin-top: 20px;
    overflow: hidden;
    height:37px;
    text-align: center;

    span{
        display: inline-block;
        vertical-align: middle;
        padding: 1px 30px;
        min-width:142px;
    }

    &:hover{
        background-color: #6375dc;
        color:#fff;
        text-decoration:none;
    }

    &:focus{
        color:#222;
    }
`;

export const OuterBtn = styled.a`
  border: 1px solid #e39f24;
  background-color: transparent;
  color: #e39f24 !important;
  display: block;
  padding: 15px 0;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 20px;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const DivAccordionMenu = styled.div`
  padding: 0 2px 0 34px;

  a {
    display: block;
    font-size: 14px;
    color: #353535;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .current {
    color: #e4a025;
  }

  .completed {
    opacity: 0.5;
  }
`;

export const ReturnCourseLink = styled.span`
  display: block;
  padding: 4px 0;
  font-size: 12px;

  a {
    font-size: 12px;
    display: inline;
  }
`;

export const ContListMyWebinar = styled.ul`
  padding: 0 0px 0 20px;
`;

export const ItemMyWebinar = styled.li`
  color: #6c828d;
  font-size: 14px;
  padding: 8px 0;

  .instructor-webinar {
    text-transform: uppercase;
    color: #353535;
    margin: 0;
    font-weight: 300;
  }

  .date-webinar {
    color: #353535;
    font-size: 13px;
    margin: 0;
    font-weight: 400;
  }

  .link-webinar {
    font-size: 13px;
    color: #255065;
  }
`;

export const TitListWebinar = styled.li`
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    color: #353535;
    list-style: none;
    padding-bottom: 4px;
`;

export const TitSideBar2 = styled.h3`
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #353535;
  margin: 4px 0;
`;

export const InstructorNameWebinar2 = styled.h4`
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #353535;
  margin-bottom: 4px;
`;

export const ContentRise = styled.div`
  width: calc(100% - 250px);
  height: 80vh;
  flex: 1 0;
  padding: 0 20px;
  
  @media (max-width: 991px) {
    width: calc(100% - 230px);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ContentGeneralRise = styled.div`
  width: calc(100% - 250px);
  float: left;
  padding: 0 20px;

  @media (max-width: 991px) {
    width: calc(100% - 230px);
  }

  @media (max-width: 767px) {
    z-index: 0;
    position: relative;
    width: 100%;
  }
`;

export const LoadImg = styled.div`
  clear: both;
  padding: 50px 10px;
  text-align: center;

  img {
    display: block;
    margin: 30px auto;
    max-width: 100%;
  }
`;

export const WrapperAddMarginRise = styled.div`
  margin: 0 60px;
  height: calc(100vh - 152px);
  background-color: #fff;
  overflow: auto;

  @media (max-width: 991px) {
    height: calc(100vh - 116px);
    margin: 0 20px;
  }

  @media (max-width: 767px) {
    height: calc(100vh - 89px);
    margin: 0;
  }
`;

export const ProgressBarLDS = styled.div`
  display: block;
  height: 7px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 2px 22px 30px;
`;

export const ColorCourseAdvance = styled.span`
  width: ${(props) => props.progress}%;
  height: 5px;
  display: block;
  background: #eec57c;
`;

export const CardImgWebinar = styled.img`
  max-width: 100px;
  max-height: 100px;
  position: absolute;
  top: 50px;
  left: 18px;

  @media (max-width: 1199px){
    top: 40px;
  }

  @media (max-width: 767px) {
    top: 10px;
    left: 50%;
    margin-left: -50px;
  }
`;

export const CardTxtWebinar = styled.div`
  background-color: #f7f7f7;
  padding: 30px 20px 30px 60px;
  text-shadow: 1px 1px 0 #fff;
  border-left: 10px solid rgb(227, 159, 36);
  margin: 0px;

  @media (max-width: 767px) {
    padding: 50px 20px 30px 24px;
  }

  h2 {
    font-family: "Cormorant Garamond", serif;
    font-size: 28px;
    margin: 0;
    font-weight: 500;
  }

  h3 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0.5px;
    font-weight: 300;
    font-size: 16px;
    margin: 1px 0;
  }
  
  h4 {
    margin: 0px; 
    font-family: "Open Sans", sans-serif; 
    font-size: 16px;
  }
  
  .speaker {
    font-family: "Open Sans", sans-serif; 
    font-size: 14px; 
    font-weight: 400;
  }
  
  .webinarsJoin {
  color: rgb(227, 159, 36); 
  font-family: "Open Sans", sans-serif; 
  text-decoration: none; 
  letter-spacing: 1px; 
  font-weight: 600; 
  margin-top: 6px; 
  display: inline-block;
  }
  
  h4, h5 {line-height: 1.4!important;}
`;

export const CardDateWebinar = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #666;
  margin: 4px 0 10px; 
  display: inline-block; 
  border-radius: 4px;
  font-size: 14px;
  
  .summer-schedule {
    font-size: 13px!important;
    font-style: italic;
  }
  
  h5 {
    font-size: inherit!important;
    margin: 0;
  }
`;

const BtnRegisterWebinar = styled.a`
  text-transform: uppercase;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 300;
  text-shadow: none;
  border-radius: 4px;
  padding: 6px 16px;
  color: #fff;
  background-color: #e39f24;
  border: 1px solid #e39f24;
  font-size: 14px;
  transition: all 300ms ease-in-out;
  margin-bottom: 10px;

  &:hover {
    color: #fff;
    text-decoration: none;
    opacity: 0.85;
  }
`;

export const BtnRegisterCourseSection = styled(BtnRegisterWebinar)`
  margin-right: 10px;
`;

export const BtnRegisterWebinarSection = styled(BtnRegisterWebinar)`
  margin-right: 10px;

  @media (max-width: 767px) {
    margin-right: 0px;
  }
`;

export const BtnUnsubscribe = styled.a`
  text-transform: uppercase;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 300;
  text-shadow: none;
  border-radius: 4px;
  padding: 6px 16px;
  color: #477ca0;
  background-color: transparent;
  border: 1px solid #477ca0;
  font-size: 14px;
  transition: all 300ms ease-in-out;

  &:hover {
    color: #fff;
    background-color: #477ca0;
    text-decoration: none;
    opacity: 0.85;
  }
`;

export const ContFormWebinar = styled.div`
    box-shadow: 0 0 20px rgba(0,0,0,0.25);
    background-color: #fff;
    padding: 20px 40px;
    max-width: 700px;
    margin: 10% auto;
    font-weight: 300;

    @media (max-width: 991px){
        max-width: 600px;        
    }
    
    @media (max-width: 767px){
        max-width: 90%;
        padding: 20px 30px;
    }
`;


export const ContFormWebinarTitle = styled.h2`
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 34px;
    display: block;
    text-align: center;

    @media (max-width: 767px){
        font-size: 28px;
    }
`;



export const ContFormWebinarInstructorTitle = styled.h3`
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 28px;
    display: block;
    text-align: center;
    margin: 0 0 30px;

    @media (max-width: 767px){
        font-size: 20px;
    }
`;


export const ContFormWebinarDate = styled.p`
    display: block;
    text-align: center;
    padding: 10px 0;
    margin: 10px 10% 24px;
    border-bottom: 1px solid #d8d8d8;
`;

export const ContFormWebinarLabel = styled.label`
    display: block;
    font-weight:300;
    margin-bottom: 10px;
`;


export const ContFormWebinarInput = styled.input`
    display: block;
    background-color:#f8f8f8;
    padding: 5px 10px;
    display: block; 
    border:none;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 20px;
`;

export const ContFormWebinarButton = styled.button`
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    color: #fff;
    background-color: #e39f24;
    letter-spacing: .5px;
    text-align: center;
    margin: 24px auto 30px;
    padding: 6px 20px;
    display: block;
    border: none;
    border-radius: 4px;
`;

export const ContCancelWebinar = styled.div`
  font-family: "Open Sans", sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 40px;
  max-width: 450px;
  margin: 10% auto;
  font-weight: 300;
  text-align: center;

  @media (max-width: 767px) {
    max-width: 90%;
    padding: 40px 24px;
  }

  p {
    margin-bottom: 24px;
  }

  a {
    min-width: 100px;
  }

  a:last-child {
    margin-left: 40px;

    @media (max-width: 767px) {
      margin-left: 30px;
    }
  }
`;

export const NotesBox = styled.div`
  width: 390px;
  border-radius: 6px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  background-color: #fff;
  position: absolute;
  z-index: 1071; /* This is for the tooltip(z-index: 1070) */

  @media (max-width: 767px) {
    width: 95%;
    min-width: 310px;
  }

  .editing-note {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #e8e8e8;

    input,
    textarea {
      width: 100%;
      border: none;
      resize: none;
      display: block;
      font-family: "Open Sans", sans-serif;
      color: #353535;
      margin-bottom: 8px;
      padding: 8px 14px;
    }

    textarea {
      height: 260px;
    }
  }
`;

export const NotesBoxHeader = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 26px 12px;
  height: 51px;
  overflow: hidden;
  cursor: pointer;

  h2 {
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    display: inline-block;
    vertical-align: middle;
  }

  a {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const NotesBoxBody = styled.div`
  height: 394px;
  overflow-y: scroll;
  position: relative;
  font-weight: 300;
`;

export const NotesBoxFooter = styled.div`
  border-top: 1px solid #d8d8d8;
  height: 48px;
  overflow: hidden;

  a {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const ListNotes = styled.ul`
    padding: 0; 

    li{
        list-style:none;
    }

    a{
        color:initial;
        display:block;
        padding: 14px 24px 0;
        transition: all 300ms ease-in-out;

        &:hover{
            text-decoration:none;
            cursor:pointer;
            background-color: #f8f8f8;
        }
    }

    a > hr{
        border-width: 1px 0 0 0;
        border-color: #d9d9d9;
        margin: 10px 0 0;
    }

    h4{
        font-size:16px;
        margin:0;
        height: 19px; 
        overflow: hidden;
        font-family: 'Open Sans', sans-serif;
        font-weight:300;
    }

    p{
        color:#353535;
        font-size: 15px;
        margin:0;
        height: 24px;
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap;
        font-weight: 300;
    }

`;

export const DetailNoteHead = styled.div`
  padding: 24px 36px 4px;

  h4 {
    margin: 0 0 8px;
  }

  input:disabled {
    background: rgba(150, 150, 150, 0.1);
  }
`;

export const DetailNoteBody = styled.div`
  padding: 6px 36px 14px;
  font-size: 15px;

  textarea:disabled {
    background: rgba(150, 150, 150, 0.1);
  }
`;

export const DateNote = styled.div`
  font-size: 13px;
  opacity: 0.66;
  margin: 0;

  span {
    display: block;
  }
`;

export const BtnAddNote = styled.a`
  position: absolute;
  right: 20px;
  bottom: 70px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  background-color: #e39f24;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 51px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: #feb532;
  }
`;

export const BtnViewAll = styled.div`
  a {
    background-color: #f7f7f7;
    text-align: center;
    text-transform: uppercase;
    padding: 11px;
    color: #477ca0;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      text-shadow: 1px 1px 0 #fff;
      background-color: #e8e8e8;
    }
  }
`;

const BtnFunctionNote = styled.a`
  transition: all 300ms ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  width: 26px;
  height: 26px;
  padding: 4px;
  border-radius: 50%;
  transition: all 300ms ease-in-out;

  &:hover {
    text-decoration: none;
    text-shadow: 1px 1px #fff;
    background-color: #f0f0f0;
  }
`;

export const BtnCloseNote = styled(BtnFunctionNote)`
  background-image: url(${CloseIcon});
  background-size: 12px;
  float: right;
  margin-top: 2px;

  &:hover {
    opacity: 0.9;
  }
`;

export const BtnBackNote = styled(BtnFunctionNote)`
  background-image: url(${GoBackIcon});
  margin-right: 12px;

  &:hover {
    opacity: 0.9;
  }
`;

export const BtnFullScreenNote = styled(BtnFunctionNote)`
  background-image: url(${FullScreenIcon});
  margin-right: 12px;
  float: right;
  margin-top: 2px;

  &:hover {
  }
`;

export const BtnResizeScreenNote = styled(BtnFunctionNote)`
  background-image: url(${ResizeScreenIcon});
  margin-right: 12px;
  float: right;
  margin-top: 2px;

  &:hover {
  }
`;

export const BtnDeleteNote = styled(BtnFunctionNote)`
  background-image: url(${DeleteIcon});
  margin: 10px 26px;

  &:hover {
    background-image: url(${DeleteBlueIcon});
  }
`;

export const BtnDownloadNote = styled(BtnFunctionNote)`
  background-image: url(${DownloadIcon});
  margin: 10px 0;

  &:hover {
    background-image: url(${DownloadBlueIcon});
  }
`;

const BtnSaveCancelEditNote = styled.button`
  transition: all 300ms ease-in-out;
  height: 26px;
  padding: 1px 18px;
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-top: 10px;
  border: none;

  &:hover {
    text-decoration: none;
  }
`;

export const BtnEditSavedNote = styled(BtnSaveCancelEditNote)`
  color: #fff;
  background-color: #477ca0;
  float: right;
  margin-right: 26px;

  &:hover {
    color: #fff;
    background-color: #0d8adf;
  }

  &:disabled {
    opacity: 0.65;
    background-color: #477ca0;
  }
`;

export const BtnCancelNote = styled(BtnSaveCancelEditNote)`
  color: #353535;
  background-color: #e8e8e8;
  float: right;
  margin-right: 14px;
  cursor: pointer;

  &:hover {
    color: #353535;
    background-color: #d8d8d8;
    text-shadow: 1px 1px 0 #fff;
  }

  &:disabled {
    opacity: 0.65;
    background-color: #e8e8e8;
  }
`;

const AlertNote = styled.div`
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 48px;

  @media (max-width: 767px) {
    padding: 12px 16px;
  }

  a,
  p {
    display: inline-block;
  }

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
`;

export const ConfirmDeleteNote = styled(AlertNote)`
  background-color: #af1a2f;
  padding: 12px 30px;

  p {
    margin: 0;
    background: url(${ConfirmDeleteIcon}) left center/16px no-repeat;
    padding: 0 20px;
  }

  a:last-child {
    margin-left: 16px;

    &:hover {
      opacity: 0.75;
    }
  }
`;

export const ConfirmPendingSaveNote = styled(AlertNote)`
  background-color: #e39f24;

  p {
    margin: 0;
    background: url(${ConfirmDeleteIcon}) left center/16px no-repeat;
    padding: 0 20px;
  }

  a:last-child {
    margin-left: 16px;

    &:hover {
      opacity: 0.75;
    }
  }
`;

export const BlackBack = styled.section`
  display: block;
  background: rgba(0, 0, 0, 0.25) !important;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;

  @media (max-width: 767px) {
  }
`;

export const ConfirmSavedNote = styled(AlertNote)`
  background-color: #4caa2b;
  padding: 12px 30px;

  p {
    margin: 0;
    background: url(${ConfirmSavedIcon}) left center/16px no-repeat;
    padding: 0 20px;
  }
`;

export const WrapperAddMarginMyNote = styled.div`
  margin: 0 60px;
  height: calc(100vh - 178px);
  min-height: 600px;

  @media (max-width: 991px) {
    height: calc(100vh - 156px);
    margin: 0 20px;
  }

  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const NotesFilters = styled.div`
  padding: 26px 0 4px;
  clear: both;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #727070;
  border-bottom: 1px solid #ccc;

  .btn {
    font-size: 16px;
    color: #727070;
    border-radius: 0;
    border: none;
  }

  .btn .caret {
    border-top: 7px dashed;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    margin-left: 10px;
  }

  .open > .dropdown-toggle.btn-default,
  .btn-default:hover {
    background-color: #f0f0f0;
    color: #353535;
  }
`;

export const BtnNotesFilters = styled.a`
  color: #727070;
  padding: 4px;
  margin-left: 5px;
  display: inline-block;
  text-align: center;
  width: 33px;
  height: 33px;
  border-radius: 4px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const CategoryNotesCont = styled.div`
  height: 35px;

  select {
    width: 46%;
    border: none;
    margin-top: 7px;
  }

  .list2 {
    float: right;
  }
`;

export const DatePickerCont = styled.div`
  height: 35px;

  input,
  span {
    display: inline-block;
    height: 27px;
  }

  input {
    width: 120px;
    font-family: "Open Sans", sans-serif;
    border: none;
    cursor: pointer;
    text-align: center;
    transition: all 300ms ease-in-out;

    &:hover {
      background: #f8f8f8;
    }

    @media (max-width: 1199px) {
      width: 112px;
    }
    @media (max-width: 991px) {
      width: 91px;
    }
  }

  span {
    @media (max-width: 1199px) {
      padding: 6px 2px;
    }
  }
`;

export const SearchNotesCont = styled.div`
  height: 35px;
  padding-top: 4px;

  input {
    display: inline-block;
    height: 27px;
    vertical-align: middle;
  }

  input {
    padding: 0 10px;
    font-family: "Open Sans", sans-serif;
    border: none;
    text-align: right;
    max-width: 87%;
    transition: all 300ms ease-in-out;

    &:hover {
      background: #f8f8f8;
    }

    @media (max-width: 1199px) {
      max-width: 83%;
    }

    @media (max-width: 991px) {
      max-width: 79%;
    }

    button {
      border: none;
      background-color: #fff;
      height: 27px;
      vertical-align: middle;
      transition: all 300ms ease-in-out;

      &:hover {
        background: #f8f8f8;
      }
    }
  }
`;

export const ListMyNotesCont = styled.div`
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  overflow-y: scroll;
  height: calc(100% - 145px);
  margin-bottom: 40px;

  ul {
    padding-top: 20px;
  }

  ul > li {
    border-left: 4px solid transparent;
    margin-left: -2px;
  }

  ul > li > a {
    padding: 14px 20px 0;
  }

  .active-note {
    border-color: #e39f24;

        &:hover {
      background: none;
    }
  }
`;

export const VisualizerMyNotesCont = styled.div`
  overflow: hidden;
  height: calc(100% - 145px);
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  border-bottom: 1px solid #ccc;
`;

export const NotesBodyMyNotesCont = styled.div`
  height: ${ props => props.visualizer && props.isMobile? 'calc(100% - 78px)' : 'calc(100% - 48px)'};
  overflow-y: scroll;
  position: relative;
  font-weight: 300;

  h4 {
    margin-top: 8px;
    font-weight: 300;
  }

  input,
  textarea {
    width: 100%;
    border: 1px solid #eee;
    background: #fafafa;
    resize: none;
    display: block;
    font-family: "Open Sans", sans-serif;
    color: #353535;
    margin-bottom: 8px;
    padding: 8px 14px;
  }

  .calc-height {
    height: calc(100% - 100px);
  }

  .calc-height textarea {
    height: 100%;
  }
`;

export const BtnNoteBook = styled.div`
  background-color: #477ca0;
  border-radius: 50%;
  border: 3px solid #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  padding-top: 6px;
  z-index: 100;
  position: absolute;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }
`;

export const IconNoteBook = styled.img`
  width: 22px;
`;

export const NoNotes = styled.div`
  padding-top: 20px;
  text-align: center;
  padding: 34px;
  font-weight: 300;

  img {
    width: 40px;
    height: 40px;
    margin: 20px auto;
    display: block;
  }

  h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 300;
  }
`;

export const BtnRegresar = styled.button`
  border: none;
  background: #fff;
  font-size: 26px;
  display: none;
  vertical-align: top;
  margin-top: 0px;
  color: #e39f24;

  @media (max-width: 767px) {
    display: inline-block;
  }
`;

export const TitH3 = styled.h3`
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 300;
`;

export const BannerHomeCoureNoPay = styled.div`
  overflow: hidden;

  @media (max-width: 767px) {
    .img-responsive {
      max-width: 270%;
      margin: 0 0 0 -90%;
    }
  }
`;

export const BtnJoinCourse = styled.a`
    position: absolute;
    bottom: 20px;
    right: 40px;
    text-align: center;
    color: #fff;
    border-color: #08c;
    background-color: #08c;
    text-transform: uppercase;
    padding: 14px 20px;
    border-radius: 3px;
    transition: all .2s ease-in-out;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    cursor: pointer;

    &:hover, &:focus{
        color: #f7f7f7;
        border-color: #0074ad;
        background-color: #0074ad;
        text-decoration:none;
    }

    @media (max-width: 767px){
        font-size:14px;
        padding: 8px;
        width: 98px;
        left:50%;
        margin-left: -49px;
    }
`;

export const BkOffset50 = styled.div`
    background: url(${Bkwhithe}) left center/50% 100% repeat-y #f7f7f7;

    @media (max-width: 991px){
        background: none;
    }
`;

export const SidebarCourseHome = styled.div`
  padding-top: 60px;

  @media (max-width: 991px) {
    padding-top: 50px;
  }
`;

export const TxtIconSideCourseHome = styled.div`
  margin-bottom: 20px;
  float: left;
  width: 100%;
  font-weight: 300;
  font-family: "Barlow", sans-serif;

  @media (max-width: 991px) {
    width: 50%;
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const IconSidebarCourseHome = styled.img`
  float: left;
  width: 32px;
`;

export const TxtSidebarCourse = styled.div`
  box-sizing: border-box;
  padding: 0 15px;
  float: left;
  width: calc(100% - 32px);

  h3 {
    margin-top: 0;
  }

  p {
    font-family: "Barlow", sans-serif;
    font-weight: 300;
    font-size: 16px;
  }
`;

export const ContTxtCourseDescription = styled.div`
  background: #f7f7f7;
  text-shadow: 1px 1px 0 #fff;
  padding: 60px 0 40px 40px;

  .margin-0 {
    margin: 0;
  }

  @media (max-width: 991px) {
    background: none;
    text-shadow: none;
    padding: 10px 0 0 0;
  }

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

export const LabelCourseFamily = styled.p`
  font-family: "Barlow", sans-serif;
  font-weight: 300;
  margin-bottom: 0;
`;

export const TxtOffset1 = styled.div`
  padding: 20px 0 10px 60px;
  font-family: "Barlow", sans-serif;
  font-weight: 300;

  @media (max-width: 767px) {
    padding-left: 0;
  }
`;

export const LessonsContainer = styled.div`
  background: #f7f7f7;
  padding: 10px 40px 40px;
  width: 100%;
  float: left;
  margin-bottom: 60px;
  margin-top: 40px;

  @media (max-width: 991px) {
    padding: 0 34px 30px;
    margin-bottom: 30px;
  }
`;

export const ListLessons = styled.ul`
  padding: 0;

  i {
    margin-right: 6px;
  }

  li {
    border-top: 1px solid #d8d8d8;
    padding: 6px;
    list-style: none;
  }

  li:last-child {
    border-bottom: 1px solid #d8d8d8;
  }

  a {
    color: #353535;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const PointJoinCourse = styled.div`
  background: url(${BkLineGray}) 0 38px repeat-x;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    background: none;
  }
`;

export const NumberPointJoin = styled.div`
  color: #e39f24;
  font-size: 24px;
  text-align: center;
  background-color: #fff;
  padding: 10px 10px 20px;
  display: inline-block;

  span {
    display: inline-block;
    border-radius: 100%;
    border: 1px solid #ccc;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    width: 52px;
    height: 52px;
    padding-top: 4px;
  }
`;

export const BannerCourseEnrolled = styled.div`
  position: relative;
  overflow: hidden;
  height: 350px;

  img {
    position: absolute;
    height: 350px;
    left: 50%;
    margin-left: -656.25px;
  }
`;

export const BkGradientCourseEnrolled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(${BkGradientBanner}) bottom center repeat-x;
`;

export const TxtBannerCourseEnrolled = styled.div`
  position: absolute;
  bottom: 40px;
  left: 40px;
  color: #fff;
  width: 70%;

  h1 {
    font-family: "Cormorant Garamond", sans-serif;
    font-size: 40px;
    font-weight: 500;
    color: #fff;
  }

  @media (max-width: 768px) {
    left: 10%;
    width: 80%;
  }
`;

export const ProgressBarBanner = styled.div`
  display: block;
  height: 7px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 10px;
`;

export const TxtProgressBarBanner = styled.p`
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  font-weight: 300;
`;

export const EnrolledLinksCourseHome = styled.ul`
  background: #e39f24;
  width: 80%;
  margin-left: 10%;
  margin-top: -30px;
  text-align: center;
  list-style: none;
  position: relative;
  z-index: 1;
  padding: 0;

  li {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 300;
  }

  a {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    padding: 14px 10px;
    display: block;
    margin: 0 16px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const DescriptionCourseEnrolled = styled.div`
  padding: 30px 0 20px;
  float: left;

  @media (max-width: 1199px) {
    padding: 20px 0 0px;
  }
`;

export const TxtIconCourseEnrolled = styled.div`
  @media (max-width: 1199px) {
    margin-top: 40px;
  }
`;

export const BtnResumeCorseEnrolled = styled.a`
  color: #fff;
  background: #477ca0;
  border: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 12px 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-transform: uppercase;

  &:hover {
    color: #fff;
    opacity: 0.85;
  }

  @media (max-width: 991px) {
    width: 70%;
  }
`;

export const BtnAllForums = styled.a`
  color: #e39f24;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  text-align: center;
  padding: 2px 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-top: 20px;

  &:hover {
    color: #e39f24;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const MonkeyRunning = styled.div`
  width: 100%;
  height: 100%;
  position: ${props => !props.isLoadedIframe? "relative" : "absolute"};
  background: #fff;

  > img {
    width: 400px;
    height: 240px;
    position: absolute;
    top: 50%;
    margin-top: -150px;
    left: 50%;
    margin-left: -200px;

    @media (max-width: 767px){
      width: 300px;
      height: 180px;
      margin-top: -90px;
      margin-left: -150px;
    }
  }
`;

export const PanelWebinarContent = styled.div`
  padding: 15px 6px;
  margin-bottom: 20px;

  .arrow-history-webinar {
    padding: 20px 0 0px 60px;
    background: url(${BKHistoryWebinar}) 14px center/34px no-repeat;
    position: relative;
    transition: all 300ms ease-in-out;
  }

  .arrow-history-webinar:hover {
    background-color: #f8f8f8;
  }

  .cont-history-webinar .arrow-history-webinar:first-child {
    background-image: url(${BKHistoryWebinarFirst});
  }

  .cont-history-webinar .arrow-history-webinar:last-child {
    background-image: url(${BKHistoryWebinarEnd});
  }

  .cont-history-webinar .arrow-history-webinar .btn-group {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .cont-history-webinar .arrow-history-webinar .dropdown-toggle {
    display: block;
    width: 100%;
    height: 100%;
  }

  .cont-history-webinar .arrow-history-webinar .btn-default {
    color: initial;
    background-color: transparent;
    border-color: transparent;
    text-align: right;
  }

  .cont-history-webinar .arrow-history-webinar .btn-default:hover,
  .cont-history-webinar .arrow-history-webinar .btn-default:focus,
  .cont-history-webinar
    .arrow-history-webinar
    .btn-group.open
    .dropdown-toggle {
    color: initial;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  .info-history-webinar {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    display: block;
    padding-left: 4px;
    margin-bottom: 20px;
  }

  .info-history-webinar span {
    display: block;
    opacity: 0.75;
  }

  .info-history-webinar span:nth-child(1) {
    font-size: 14px
  }

  .divider-webinar {
    border: none;
    border-bottom: 1px solid #d8d8d8;
    width: 100%;
    display: block;
    margin: 0;
  }

  .btn-download-webinar {
    position: absolute;
    top: 30px;
    text-align: center;
    color: initial;
  }

  .btn-audio-down {
    right: 90px;
    font-size: 16px;
  }
  .btn-video-down {
    right: 60px;
  }
  .btn-comments-down {
    right: 20px;
  }

  @media (max-width: 991px) {
    margin-bottom: 74px;
  }

  @media (max-width: 767px) {
    margin-bottom: 74px;

    .arrow-history-webinar {
      padding-left: 0;
    }
    .info-history-webinar {
      padding-left: 10px;
      padding-right: 30px;
    }
    .arrow-history-webinar,
    .cont-history-webinar .arrow-history-webinar:first-child,
    .cont-history-webinar .arrow-history-webinar:last-child {
      background-image: none;
    }
  }
`;

export const NoWebinarSetup = styled.div`
  clear: both;
  padding: 20px 0;
  text-align: center;

  p {
    background: url(${NoWebinar}) 22px center/38px no-repeat #f7f7f7;
    border: 1px solid #d7d7d7;
    padding: 22px 28px 22px 70px;
    font-weight: 300;
    display: inline-block;
  }
`;

export const SignUpModal = styled.div`
  p {
    font-weight: 300;
  }

  .box-registro {
    padding: 20px 30px 10px;
    /*margin-top: 60px;
        margin-bottom: 100px;*/
    background-color: rgba(250, 250, 250, 1);
  }

  .cont-campo-form {
    min-height: 88px;
  }
  .form-inputs {
    border: none;
    border-bottom: 1px solid #d8d8d8;
    font-size: initial;
    padding: 5px 10px 4px;
    width: 100%;
    display: block;
    margin-bottom: 6px;
    background: none;
    min-height: 36px;
    font-weight: 300;
  }
  .form-label {
    display: block;
    font-size: 10px;
    font-weight: 300;
    margin-bottom: 2px;
  }

  .tit-form-registro {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    position: relative;
  }

  .acceso-login-old {
    display: block;
    padding: 16px 0 10px;
    text-align: center;
    font-weight: 400;
    border-top: 1px solid #d9d9d9;
    margin: 30px -30px 0;
  }
  .acceso-login-old a,
  .acceso-login-old a:hover {
    text-transform: uppercase;
    letter-spacing: 0.6px;
    color: #e39f24;
    display: inline-block;
    padding-left: 10px;
  }

  .tit-form-registro span {
    font-weight: 400;
  }

  .mb-tit-form {
    margin-bottom: 30px;
  }

  .registerbtn {
    background-color: #e39f24;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    border: none;
    padding: 6px 20px;
    border-radius: 4px;
    float: right;

    &:disabled {
      opacity: 0.6;
    }
  }
`;

export const ItemQuestions = styled.li`
  color: #e39f24;

  .text-question {
    color: #353535;
    margin: 0;
  }
`;

export const PushPie = styled.div`
  width: 100%;
  height: 50px;
  float: left;
`;


/* ============================== New update styles ==================================== */
export const ContMenuCurse = styled.div`
    border-top: 1px solid #d7d7d7; 
    border-bottom: 1px solid #d7d7d7;
`;

export const MenuCursosDrag  = styled.ul`
    padding:0;
    list-style:none;
    margin:0;
    font-family: 'Open Sans', sans-serif;
    text-align: center;

    @media  (max-width: 456px){
        min-width: 430px;
        text-align: left;
    }

    li{
        display:inline-block;
    }

    li.activo a{
        display:inline-block;
        border-color:#e39f24;
    }

    a{
        transition: all 300ms ease-in-out;
        padding:6px 16px 4px;
        color:#777;
        display: block;
        border-bottom: 4px solid transparent;
        font-size: 14px;
        margin: 0 26px;

        &:hover{
            border-color:#e39f24;
            text-decoration:none;
        }
        &:focus{
            border-color:#e39f24;
            text-decoration:none;
        }

        @media  (max-width: 991px){
            padding:6px 12px 4px;
        }

        @media  (max-width: 767px){
            margin: 0 8px;
            padding:6px 6px 4px;
        }

    }
    
`;


export const NoProgressBarLDS= styled.div`
    display: block;
    height: 7px;
    box-sizing: border-box;
    margin: 2px 22px 30px;
`;

export const BtnOutlineAzul = styled.a`
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    color: #477CA0;
    background:#EEF6FB;
    border-radius: 5px;
    border:1px solid #477CA0;
    display:inline-block;
    letter-spacing:1px;
    padding:5px 16px;
    transition: all 300ms ease-in-out;
    font-weight:400;
    position:relative;
    cursor: pointer;
  text-decoration: none;

    &:hover{
        background: #5dade2;
        color: #fff;
        border-color:#5dade2;
        text-decoration: none;
    }

    &:focus{
        background: #5dade2;
        color: #fff;
        border-color:#5dade2;
        text-decoration: none;
    }
`;

export const BtnCardTransparent = styled.a`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 !important;
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }

    &:focus{
        text-decoration: none;
    }
`;

export const OfertaCursosBlock  = styled.div`
    padding: 30px 15px 30px 45px;
    position: relative;
    float: left;
    width: 100%;

    .gold{border-top-color: #e39f24;}
    .green{border-top-color: #8cc3bb;}
    .red{border-top-color: #f48975;}
    .blue-free {
      border-top-color: #23527C
    }

    &:before{
        content:'';
        position:absolute;
        top:51px;
        left:24px;
        width: 20px;
        height: 20px;
        background:url(${BulletOrange}) center/20px no-repeat; 
        border-radius:100%;
        border:1px solid #e39f24;    
        z-index: 2;   
    }
`;

export const TitulOfertaCurso = styled.h3`
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif!important;
    letter-spacing: 0.5px;
    font-weight: 400;
    border-bottom:1px solid #e39f24;
    padding-bottom: 6px;
    padding-left:3px;
`;

export const EtiquetaGratuita  = styled.p`
    background: url(${CopaFree}) 0 4px/20px 20px no-repeat;  
    color:#5CA9FF;
    padding-left:26px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
`;

export const EtiquetaPaga  = styled.p`
    background: url(${CopaPaga}) 0 4px/20px 20px no-repeat;  
    color:#5CA9FF;
    padding-left:26px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
`;

export const VerMasCard = styled.a`
    display:inline-block !important;
    position:relative;
    text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const ProximamenteCard = styled.p`
    text-align: left !important;
    font-size: 16px !important;
    margin:12px 0 !important;
    display: inline-block;
`;

export const TagLevelCourse = styled.div`
    position:absolute;
    top:20px;
    left: 20px;
    background-color: rgba(255,255,255,0.75);
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #353535;
    font-family: 'Open Sans', sans-serif;
    padding:3px 18px;
    border-radius: 4px;
`;

export const BtnGoldCatalog = styled.a`
    padding: 6px 20px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Open Sans',sans-serif;
    background-color: #e39f24;
    border-radius: 4px;
    margin: 12px 0;
    display: inline-block;
    font-weight: 400;
    letter-spacing: 1px;
    text-decoration: none;

    &:hover{
        color:#fff;
        text-decoration:none;
        opacity:.8;
    }

    &:focus{
        color:#fff;
        text-decoration:none;
        opacity:.8;
    }
`;

export const WrapCursoPromo = styled.div`
    background-color:#F3FBFD;
    padding: 30px;
    max-width: 900px;
    float:left;
    margin-top:40px;

    img{
        width:40%;
        float: right;

        @media  (max-width: 767px){
            width:100%;
        }
    }
`;

export const TxtCursoPromo = styled.div`
    width:60%;
    float: left;
    padding-right: 18px;



    @media  (max-width: 767px){
        width:100%;
        margin-bottom:20px;
        float: right;
        margin-bottom:30px;
    }

    h2{
        font-family: 'Cormorant Garamond',serif;
        margin-top: 12px;
        

        @media  (max-width: 767px){
            margin-top:0;
        }
    }

    a{
        display:inline-block;
        padding:8px 12px;
        color:#fff;
        text-transform: uppercase;
        background-color:#477CA0;
        font-family: 'Open Sans', sans-serif;
        font-weight:400;
        font-size:14px;
        border-radius:4px;
        margin-top:6px; 
    }
`;

export const BlockGeneralMisCursos  = styled.div`
    padding: 30px 15px 30px 45px;
    position: relative;
    float: left;
    width: 100%;

    .gold{border-top-color: #e39f24;}
    .green{border-top-color: #8cc3bb;}
    .red{border-top-color: #f48975;}

`;

export const RecordingContent = styled.div` 
    overflow: hidden;
    padding: 10px 0;
`;
export const ItemRecordingIcon = styled.div` 
    float: left;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
`;
export const ItemRecordingInfo = styled.div` 
    display: inline-block;
    color: #000;
`;