import {getCountriesAPI, loadConfigurationsByPlatform, loadMenuLinks} from "../Repository/Requests";
import Actions                                                        from "./Store/Actions";
import {getSessionKey, SessionsLabels}                                from "../Helpers/SessionStorage";
import {toast}                                                        from "react-toastify";
import {toastConfig}                                                  from "../Helpers/MainHelper";
import {isEmpty}                                                      from "lodash"


export const loadConfigurationData = async () => {
    try {
        const platformID = getSessionKey(SessionsLabels.PLATFORM_ID);
        return await loadConfigurationsByPlatform(platformID);
    } catch (error) {
        console.log(error);
        return [];
    }
}

export const loadConfiguration = () => async dispatch => {
    let data = [];
    try {
        const platformID = getSessionKey(SessionsLabels.PLATFORM_ID);
        data = await loadConfigurationsByPlatform(platformID);
    } catch (error) {
        console.log(error);
    }
    dispatch({
        type:    Actions.LOAD_CONFIGURATIONS,
        payload: data
    })
}

export const getHeaderMenu = () => async dispatch => {
    try {
        const platformID = getSessionKey(SessionsLabels.PLATFORM_ID);
        const data = await loadMenuLinks(platformID);


        if (!isEmpty(data.status)) throw new Error(data.data);

            dispatch({
                type:    Actions.LOAD_MENU_LINKS,
                payload: data
            });
    } catch (error) {
        toast.error(error, toastConfig);
    }
}

export const loadCountriesAPI = async () => {
    try {
        let countries = await getCountriesAPI();

        return countries.map(c => ({
            name:         c.name,
            translations: c.translations,
            code:         c.alpha2Code,
        }))
    } catch (error) {
        console.log(error);
        return [];
    }
}