import { combineReducers }   from "redux";
import types                 from "./Actions";
import { orderByDescending } from "../../Helpers/MainHelper";

const initialState = {
    payload: [],
    loading: true,
    status: null,
};

const configurations = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_CONFIGURATIONS:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const platforms = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_PLATFORMS:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const platform = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_PLATFORM:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const courses = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_COURSES:
            return {payload: action.payload, loading: false};
        case types.ERROR_COURSES:
            return {payload: action.payload, loading: false};
        default:
            return state
    }

}

const enrolled_courses = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_ENROLLED_COURSES:
            return {payload: action.payload, loading: false};

        case types.ERROR_ENROLLED_COURSES_NOT_FOUND:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const course = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_COURSES:
            return {payload: action.payload, loading: false};

        case types.ERROR_COURSES_NOT_FOUND:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const course_marketing_info = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_COURSE_MARKETING_INFO:
            return {payload: action.payload, loading: false};

        case types.ERROR_COURSE_MARKETING_INFO_NOT_FOUND:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const section = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_SECTION:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const resources = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_RESOURCES_BY_COURSE:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const sections = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_SECTIONS_BY_COURSE:
            return {payload: action.payload, loading: false};

        case types.ERROR_SECTIONS_NOT_FOUND:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_USER_INFO:
            return {payload: action.payload, loading: false};
        case types.ERROR_USER_NOT_FOUND:
            return {payload: action.payload, loading: false};
        case types.USER_LOADING:
            return {...state, loading: true}
        default:
            return state;
    }
};

const website_menus = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_MENU_LINKS:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const webinars = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_SUBSCRIBED_WEBINARS:
            return {payload: orderByDescending(action.payload, 'webinar.webinar_date'), loading: false};

        case types.REGISTER_WEBINARS:
            return {
                payload: orderByDescending([...state.payload.filter(d => d.id !== action.payload.id), action.payload], 'webinar_date'),
                loading: false
            };

        case types.UNSUBSCRIBED_WEBINARS:
            return {
                payload: [...state.payload.map(d => {
                    if (d.webinar.id === action.payload) d.status = 'cancel';
                    return d
                })], loading: false
            };

        case types.ERROR_WEBINARS_NOT_FOUND:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const webinarsUpcoming = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_NEXT_WEBINARS:
            return {payload: action.payload, loading: false};

        case types.ERROR_NEXT_WEBINARS_NOT_FOUND:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const webinarsPrevious = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_PREVIOUS_WEBINARS:
            return {payload: orderByDescending(action.payload), loading: false};

        case types.ERROR_PREVIOUS_WEBINARS_NOT_FOUND:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

const notes = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_NOTES:
            return {payload: orderByDescending(action.payload), loading: false, status: null};

        case types.CREATE_NOTE:
            return {payload: orderByDescending([...state.payload, action.payload]), loading: false, status: 'created'};

        case types.UPDATE_NOTE:
            return {
                payload: orderByDescending([...state.payload.filter(d => d.id !== action.payload.id), action.payload]),
                loading: false,
                status: 'updated'
            };

        case types.DELETE_NOTE:
            return {
                payload: [...state.payload.filter(d => d.id !== action.payload)],
                loading: false,
                status: 'deleted'
            };

        case types.UPDATE_STATUS:
            return {payload: [...state.payload], loading: false, status: null};

        case types.NOTES_LOADING:
            return {...state, loading: true};

        default:
            return state;
    }
};

const noteSelected = (state = initialState, action) => {
    switch (action.type) {
        case types.ON_SELECT_NOTE:
            return {payload: action.payload, loading: false};

        default:
            return state;
    }
};

//Creating store.
export default combineReducers({
    configurations,
    platforms,
    platform,
    courses,
    enrolled_courses,
    course,
    course_marketing_info,
    sections,
    resources,
    section,
    user,
    website_menus,
    webinars,
    webinarsUpcoming,
    webinarsPrevious,
    notes,
    noteSelected,
});
